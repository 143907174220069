<template>
    <div class='resume-container'>
        <div>
            <div class='header-left'>
                <h3>Abhishek Pai</h3>
                <p>Remote UK & EU</p>
            </div>
            <div class='header-right'>
                <p><a href='tel:+44 7552 770334'>+44 7552 770334</a></p>
                <p><a href='mailto:abpai94@gmail.com'>abpai94@gmail.com</a></p>
            </div>
        </div>
        <div class='main-body-left'>
            <div class='experience-grid'>
                <div class="horizontal-separator">
                    <hr>
                </div>
                <div class='subtitle-grid'>
                    <h3>Experience</h3>
                </div>
                <div class='company-grid'>
                    <h4>Worteks<span><b> / Infrastructure Engineer </b></span><span>
                        </span></h4>
                    <h5>March 2024 - Present</h5>
                </div>
                <div class='company-summary'>
                    <p>Administering backend server infrastructure.</p>
                </div>
                <div class='block-details'>
                    <ul>
                        <li>Integrating OIDC, SAML and CAS for SSO.</li>
                        <li>Configuring Lemonldap::NG and Shibboleth SSO.</li>
                        <li>PHP and Java development for IAM application stack.</li>
                        <li>Supporting and resolving customer tickets.</li>
                        <li>Updating and upgrading hosted services and applications.</li>
                    </ul>
                </div>
                <div class='company-grid'>
                    <h4>IdentIT<span><b> / Consultant Engineer </b></span><span>
                        </span></h4>
                    <h5>August 2022 - December 2023</h5>
                </div>
                <div class='company-summary'>
                    <p>Customising ForgeRock IAM stack for Proximus.</p>
                </div>
                <div class='block-details'>
                    <ul>
                        <li>Integrating Itsme SSO and SMS flow with IAM stack.</li>
                        <li>Custom Java and scripted nodes for Tree development.</li>
                        <li>Implementing and improving Ansible playbooks.</li>
                        <li>Automating AM CI/CD Jenkins pipeline with Amster.</li>
                        <li>Documenting tools for debugging and deployment automation.</li>
                    </ul>
                </div>
                <div class='company-grid'>
                    <h4>ForgeRock<span><b> / Support Engineer </b></span><span>
                        </span></h4>
                    <h5>September 2020 - April 2022</h5>
                </div>
                <div class='company-summary'>
                    <p>Support and resolve customer’s issues with IAM products.</p>
                </div>
                <div class='block-details'>
                    <ul>
                        <li>Responding and adhering to strict SLAs.</li>
                        <li>Analysis of customer configuration, logs and audit files.</li>
                        <li>Replicating databases and directory services environments.</li>
                        <li>Contributing to knowledge base articles and documentation.</li>
                    </ul>
                </div>
                <div class='company-grid'>
                    <h4>SCISYS<span><b> / Software Engineer </b></span><span>
                        </span></h4>
                    <h5>September 2017 - August 2020</h5>
                </div>
                <div class='company-summary'>
                    <p>Feature development for Galileo ground control segment ESA.</p>
                </div>
                <div class='block-details'>
                    <ul>
                        <li>Developing features and fixing bugs in Java.</li>
                        <li>Refactoring and upgrading MySQL with Hibernate ORM.</li>
                        <li>Working in SUSE Linux to deploy applications.</li>
                        <li>Developing unit tests using JUnit and Mockito.</li>
                        <li>Scrums and sprints to develop features and maintain velocity.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class='main-body-right'>
            <div class="horizontal-separator">
                <hr>
            </div>
            <div class='education-grid'>
                <div class='subtitle-grid'>
                    <h3>Education</h3>
                </div>
                <h4>MSc Computer Science</h4>
                <p>University of Birmingham</p>
                <h5>September 2016 - September 2017</h5>
                <h4>BSc Chemistry w/ Pharmacology</h4>
                <p>University of Birmingham</p>
                <h5>September 2013 - July 2016</h5>
                <div class="horizontal-separator">
                    <hr>
                </div>
            </div>
            <div class='tools-grid'>
                <div class='subtitle-grid'>
                    <h3>Tools</h3>
                </div>
                <div class="tools-square">
                    <button class="square" @click="handleClick(item)" v-for="item in squares" :key="item.id">{{ item.text
                    }}</button>
                </div>
            </div>
            <div class="horizontal-separator">
                <hr>
            </div>
            <div class='certification-grid'>
                <div class='subtitle-grid'>
                    <h3>Certification</h3>
                </div>
                <div class='block-details'>
                    <ul>
                        <li><a href='https://www.credly.com/badges/11745538-8d6b-4114-8bc2-df41a9956337'>Linux System Administrator</a></li>
                        <li><a href='https://www.credly.com/badges/d1509fbb-bd9b-493d-92d2-a8a9e20f018d'>ForgeRock Identity Cloud</a></li>
                        <li><a href='https://www.credly.com/badges/0871b66c-22c0-411f-98e7-ad8aee22253a'>ForgeRock Access Management</a></li>
                        <li><a href='https://www.credly.com/badges/809ccdbf-b51f-49d4-948e-06b45bb193b6'>ForgeRock Identity Management</a></li>
                    </ul>
                </div>
                <div class="horizontal-separator">
                    <hr>
                </div>
            </div>
            <div class='personalprojects-grid'>
                <div class='subtitle-grid'>
                    <h3>Personal Projects</h3>
                </div>
                <div class='company-summary'>
                    Home Server</div>
                <div class='block-details'>
                    <ul>
                        <li>Ansible Deployment Automation</li>
                        <li>Docker compose configuration</li>
                        <li>Personal website developed in vue</li>
                        <li>Traefik Reverse Proxy with HTTPS</li>
                        <li>Nextcloud and onlyoffice file hosting</li>
                        <li>Postgres database for configurations</li>
                        <li>Keycloak SSO with Authelia</li>
                        <li>ZFS RAID for photo archive</li>
                        <li>GCP Bucket backups via cronjobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            squares: [
                { text: "OIDC", link: "https://www.abhishekpai.co.uk/resume" },
                { text: "SAML", link: "https://www.abhishekpai.co.uk/resume" },
                { text: "LemonLDAP::NG", link: "https://lemonldap-ng.org/documentation/latest/presentation.html" },
                { text: "AM", link: "https://backstage.forgerock.com/docs/am/7.5/eval-guide/about-am.html" },
                { text: "IDM", link: "https://docs.pingidentity.com/pingidm/7.5/getting-started/about-idm.html" },
                { text: "LDAP", link: "https://www.redhat.com/en/topics/security/what-is-ldap-authentication" },
                { text: "MySQL", link: "https://dev.mysql.com/doc/refman/8.0/en/what-is-mysql.html" },
                { text: "Postgres", link: "https://www.postgresql.org/about/" },
                { text: "Amster", link: "https://backstage.forgerock.com/docs/amster/7.4/user-guide/amster-introduction.html" },
                { text: "Ansible", link: "https://www.ansible.com/overview/how-ansible-works" },
                { text: "Jenkins", link: "https://www.jenkins.io" },
                { text: "Linux", link: "https://www.redhat.com/en/topics/linux/what-is-linux" },
                { text: "Docker", link: "https://www.docker.com/get-started/" },
                { text: "Traefik", link: "https://traefik.io/traefik/" },
                { text: "Apache2", link: "https://httpd.apache.org/" },
                { text: "Git", link: "https://git-scm.com/book/en/v2/Getting-Started-What-is-Git%3F" },
                { text: "PHP", link: "https://www.php.net/" },
                { text: "Java", link: "https://www.java.com/en/download/help/whatis_java.html" },
                { text: "Maven", link: "https://maven.apache.org/what-is-maven.html" },
            ],
        };
    },
    methods: {
        handleClick(item) {
            window.location.href = item.link;
        }
    }
}

</script>

<style>
.resume-container {
    margin-top: 15px
}

.header-left,
.header-right {
    float: left;
    width: 50%;
}

.header-right {
    text-align: right;
}

.horizontal-separator {
    margin-top: 15px;
}

.main-body-left,
.main-body-right {
    float: left;
    width: 60%;
}

.main-body-right {
    float: right;
    max-width: 325px;
}

.experience-grid {
    grid-column-end: span 1;
    grid-row-end: span 6;
}

.tools-grid {
    grid-row-end: span 1;
}

.company-grid,
.subtitle-grid {
    padding: 10px 0px 10px 0px;
}

.company-summary {
    padding: 0px 0px 10px 0px;
}

.block-details {
    padding-left: 15px;
}

h3 {
    color: #d44500;
    padding-bottom: 5px;
}

h5 {
    color: #666666;
    font-weight: normal;
}

.square {
    width: fit-content;
    border: 1px solid #d44500;
    border-radius: 20px;
    text-align: center;
    padding: 10px;
    background-color: #d44500;
    color: white;
    float: left;
    margin: 1px;
    font-size: 12.5px;
}

.square:hover {
    background-color: white;
    color: #d44500;
}

.tools-grid {
    float: inline-start;
    padding-bottom: 10px;
}

span b {
    font-weight: normal;
}

span c {
    font-size: 15px;
    color: #666666;
}
</style>