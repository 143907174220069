<template>
    <div>
        <h1>
            Welcome!
        </h1>
    </div>
    <div class="main-body">
        <br>
        <img class="cycling-img" src="../assets/cycling.jpg" alt="Cycling">
        <p>Hello, I'm Abhishek Pai and welcome to my website. I am a passionate techie whose journey began when I was in high school I fondly remember building my first PC. I've been fortunate enough to turn this interest into a career, starting off as a Software Engineer in the tech industry. Over the years, I've specialized in various areas of expertise such as Identity and Access Management (IAM), System Administration, and DevOps</p>
        <br>
        <p>Open source projects hold a deep passion from me, and I actively contribute to a few projects within the Identity and Access Management (IAM) stack, this passion extends to sponsoring projects which I self-host. My enthusiasm for open source projects has extended to self-hosting on a server, which too has evolved alongside my expanding knowledge base. Beyond hands-on experience, I have also earned various certifications to strengthen my foundational knowledge and enhance myself. You can expect both my website and deployments to evolve over time as I explore new technologies.</p>
        <br>
        <h2>Server Infrastructure</h2>
        <img class="hdd-rack-img" src="../assets/hdd_rack.jpg" alt="hdd-rack">
        <p>The current server infrastructure is equipped with an AMD Ryzen 7 8700G processor and a substantial 92 GB DDR5 RAM. The system includes one primary boot SSD, two separate NVMe SSDs configured for ZFS Cache VDEVs and Log VDEVs respectively, and two RAIDZ1 arrays, each comprised of four 1.82 TiB drives. This setup yields a total storage capacity of 10.43 TiB while providing 2 drive failures (1 per RAIDZ1 array).</p>
        <br>
        <img class="server-cpu-img" src="../assets/server_cpu.jpg" alt="server-cpu">
        <p>This server has enabled me to move away from using Cloud based storage providers by using nextcloud for documents and photos while using onlyoffice for the office suite. I have deployed ollama along with open-webui to run "open source" LLMs instead of using ChatGPT, Gemini or other SaaS provided solutions. The services are protected by Traefik reverse proxy which also provides HTTPS with SSO solutions from Keycloak and Authelia for all the applications. A postgres instance hosts databases for the various applications.</p>
        <br/>
        <p>I host a VPN to access the services securely to update and monitor the docker containers. The VPN has the added benefit of the PiHole DNS-based ad-blocking for my devices on my home network. The deployment automated with ansible playbooks deploying docker containers to host all the services. In addition I have a PiKVM which enables backup VPN and KVM access to the server incase of emergency.</p>
        <br>
        <h2>Previous infrastructure</h2>
        <img class="franklin-img" src="../assets/franklin.jpg" alt="franklin">
        <p>Self hosting started out as a small project with the help of one Raspberry Pi 3B but later expanded to 4B+. The first raspberry pi handles the ingress into my home network via the WireGuard VPN, Traefik HTTPS, PiHole ad-blocking, Authelia and Website.</p>
        <br>
        <img class="eleanor-img" src="../assets/eleanor.jpg" alt="eleanor">
        <p>Inaddition I have a second Raspberry Pi that handles the NAS component of my home server setup called Eleanor. Over the years I have accummulated a large array of photos which I have been storing in external HDDs laying around my desk.</p>
        <br/>
        <p>The RockPi-SATA HAT provides slots for additional HDDs with NFS Server configured along with Nextcloud deployed on Docker which led to self-hosting my photos and documents. I used to run regular cronjobs to backup everything onto GCP buckets to ensure I have some redundancy incase of a catastrophic issue occurring with my setup.</p>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.welcome,
h1 {
    padding: 15px 15px 0px 15px;
    font-size: 50px;
    text-align: center;
    color: #d44500;
}

h2 {
    padding: 15px 15px 15px 15px;
    font-size: 25px;
    text-align: center;
    color: #d44500;
}

.cycling-img {
    float: right;
    width:50%;
    margin: 10px;
}

.hdd-rack-img {
    float: left;
    width: 30%;
    margin: 10px;
}

.server-cpu-img {
    float: right;
    width: 25%;
    margin: 10px;
}

.franklin-img {
    float: left;
    width: 25%;
    margin: 10px;
}


.eleanor-img {
    float: right;
    width: 25%;
    margin: 10px;
}
</style>